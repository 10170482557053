import React, { useState } from 'react'
import { Redirect } from 'react-router-dom/cjs/react-router-dom'
import {
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Button,
  Header,
  Image,
  Modal,
} from 'semantic-ui-react'
import { PiriodForm } from 'src/hooks/usePiriod'

export function SuscribeButton({ customer = {}, metadata = {} }) {
  const [open, setOpen] = useState(false)
  const currentURL = window.location.href
  const isCardResponse = String(currentURL).includes('source_status')

  const onClose = () => {
    window.piriodSettings = {}
    setOpen(false)
  }

  const shouldOpen = isCardResponse && window.piriodSettings?.metadata?.companyId

  return(
    <Button
      color="blue"
      className=""
      type="button"
      href="/suscripcion"
    >
      Contratar Pepper
    </Button>
  )

  // return (
  //   <Modal
  //     onClose={() => setOpen(false)}
  //     onOpen={() => setOpen(true)}
  //     open={open}
  //     style={{ zIndex: 1033 }}
  //     trigger={            
  //     <Button
  //       color="blue"
  //       className=""
  //       type="button"
  //     >
  //       Contratar Pepper
  //     </Button>}
  //   >
  //     <ModalHeader>Formulario de Suscripción</ModalHeader>
  //     <ModalContent scrolling>
  //       <PiriodForm customer={customer} metadata={metadata} />
  //     </ModalContent>
  //     <ModalActions>
  //       <Button color='black' onClick={onClose}>
  //         Cancelar
  //       </Button>
  //     </ModalActions>
  //   </Modal>
  // )
}